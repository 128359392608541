
import { Component, Vue } from 'vue-property-decorator';
import { Chart } from '@/libs/chart-lib';
import { createChart } from './npv-calculator-chart-config';
import { getRawValueByUnit, LossSeverityModule } from '@/store/modules/company-tabs/loss-severity';
import {
  formatNumber,
  getCurrencySymbol,
  sumWeightedNPVItems,
  UnitCaptionMap,
} from '../../AdvisoryOverview/utils';

const randomColor = require('randomcolor');

@Component
export default class NpvCalculatorChart extends Vue {
  private chart?: Chart;

  mounted() {
    if (this.tableData.length > 0) {
      const { chart } = createChart({
        container: this.$refs.chartNode as HTMLElement,
        data: this.chartData,
      });
      this.chart = chart;
    }
  }

  beforeDestroy() {
    delete this.chart;
  }

  activated() {
    window.dispatchEvent(new Event('resize'));
  }

  getColor(index: number) {
    return randomColor({
      luminosity: 'light',
      seed: index * 5,
    });
  }

  get tableData() {
    return LossSeverityModule.npvCalculatorTableData;
  }

  get totalNpv() {
    let sumNpv = this.tableData[0].npv;
    for (let i = 1; i < this.tableData.length; i++) {
      sumNpv = sumWeightedNPVItems(sumNpv, this.tableData[i].npv);
    }
    return sumNpv;
  }

  get chartData() {
    let npvSum = this.totalNpv;
    return this.tableData.map(({ attachment, limit, npv }, ind, arr) => {
      const severity = sumWeightedNPVItems(attachment, limit);
      if (ind !== 0) {
        npvSum = sumWeightedNPVItems(npvSum, arr[ind - 1].npv, true);
      }
      const del = getRawValueByUnit(1, LossSeverityModule.unit);
      let nextSumValue = 0;
      if (ind < arr.length) {
        nextSumValue = sumWeightedNPVItems(npvSum, npv, true).WeightedNPVRaw / del;
      }
      return {
        severityValue: { from: attachment.WeightedNPV, to: severity.WeightedNPV },
        severityLabel:
          getCurrencySymbol(LossSeverityModule.currency) + formatNumber(limit.WeightedNPVRaw),
        npvValue: { from: nextSumValue, to: npvSum.WeightedNPVRaw / del },
        npvLabel:
          getCurrencySymbol(LossSeverityModule.currency) +
          formatNumber(Math.round(npv.WeightedNPVRaw)),
        color: this.getColor(this.tableData.length - ind) as string,
      };
    });
  }

  get legendText() {
    return (
      getCurrencySymbol(LossSeverityModule.currency) + ' ' + UnitCaptionMap[LossSeverityModule.unit]
    );
  }
}
