
import InsideBtnSpinner from '@/components/InsideBtnSpinner.vue';
import { LossSeverityModule, LossSeverityUnit } from '@/store/modules/company-tabs/loss-severity';
import { Nullable } from '@/types';
import { Component, Vue } from 'vue-property-decorator';
import { formatCurrencyV2 } from '../../AdvisoryOverview/utils';
import SeverityValueInput from '../elements/SeverityValueInput.vue';
const randomColor = require('randomcolor');

@Component({ components: { SeverityValueInput, InsideBtnSpinner } })
export default class NpvCalculatorTable extends Vue {
  isLoading = false;
  attachment: Nullable<string> = null;
  limit: Nullable<string> = null;
  maxInsurableLoss: Nullable<string> = null;

  headerTitles = [
    'layer',
    'attachment point',
    '',
    'Limit',
    'Severity Max',
    'Max Insurable Loss',
    'NPV',
  ];

  clearFields() {
    this.attachment = null;
    this.limit = null;
    this.maxInsurableLoss = null;
  }

  get currency() {
    return LossSeverityModule.currency;
  }

  get unit() {
    return LossSeverityModule.unit;
  }

  get isValid() {
    return this.attachment && this.limit && !this.hasError;
  }

  get tableData() {
    return LossSeverityModule.npvCalculatorTableData;
  }

  get maxSeverity() {
    const last = this.tableData[0];
    if (!last) return 0;
    const { attachment, limit } = last;
    return attachment.WeightedNPV + limit.WeightedNPV;
  }

  get hasError() {
    return Number(this.attachment) < this.maxSeverity;
  }

  _formatCurrencyV2(value: number, unit?: LossSeverityUnit) {
    return formatCurrencyV2(value, LossSeverityModule.currency, unit);
  }

  getColor(index: number) {
    return randomColor({
      luminosity: 'light',
      seed: index * 5,
    });
  }

  submit() {
    const { attachment, limit, maxInsurableLoss } = this;
    this.isLoading = true;
    LossSeverityModule.fetchWeightedNpv({
      severityFrom: Number(attachment),
      severityTo: Number(attachment) + Number(limit),
      maxInsurableLoss: maxInsurableLoss ? Number(maxInsurableLoss) : null,
    })
      .then(() => {
        this.clearFields();
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  reset() {
    LossSeverityModule.clearNpvCalculatorStorage();
  }
}
