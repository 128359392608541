import { Chart } from '@/libs/chart-lib/index';

const canvasPaddings = [10, 0, 5, 0];
const X_MIN = 0;
const X_MAX = 9;

interface ChartData {
  container: HTMLElement;
  data: {
    severityValue: { from: number; to: number };
    severityLabel: string;
    npvValue: { from: number; to: number };
    npvLabel: string;
    color: string;
  }[];
}

export function createChart({ container, data }: ChartData) {
  const severityToNpvCoef = data[0].severityValue.to / data[0].npvValue.to;

  const chart: Chart = new Chart(container, [X_MIN, X_MAX], [0, 1]);

  const { xAxis, yAxis } = chart;
  const BLACK = '#000000';
  const BLACK_10 = '#00000033';
  const BLACK_20 = '#0000001A';

  // AXIS
  yAxis.display = true;
  yAxis.setOptions('start', 1, BLACK_10);
  yAxis.ticks.setOptions(false, 'fixedCount', [data.length]);
  yAxis.ticks.label.setOptions(false);

  xAxis.display = true;
  xAxis.setOptions('start', 1, BLACK);
  xAxis.ticks.display = false;
  xAxis.ticks.label.display = false;

  // SERIES COMMON
  const xList = [1, 4, 5, 8];

  // AREA CHARTS
  const configArea = (
    name: string,
    severity: { from: number; to: number },
    npv: { from: number; to: number },
    color: string
  ) => {
    const topArr = [
      severity.to,
      severity.to,
      npv.to * severityToNpvCoef,
      npv.to * severityToNpvCoef,
    ];
    const botArr = [
      severity.from,
      severity.from,
      npv.from * severityToNpvCoef,
      npv.from * severityToNpvCoef,
    ];
    const PLOT_NAME = `${name}_AREA`;
    chart.addPlot(PLOT_NAME, 'area', 0, 'transparent', color);
    chart
      .addSeries(PLOT_NAME, [
        [...xList, ...[...xList].reverse()],
        [...topArr, ...[...botArr].reverse()],
      ])
      .setPlotsIds(PLOT_NAME);
  };

  data.forEach(({ severityValue, npvValue, color }, ind) => {
    configArea(String(ind), severityValue, npvValue, color);
  });

  // TRANSPARENT MID AREA
  chart.addPlot('TRANSPARENT_AREA', 'area', 0, 'transparent', BLACK_20);
  chart
    .addSeries('TRANSPARENT_AREA', [
      [4, 5, 5, 4],
      [data[0].severityValue.to, data[0].severityValue.to, 0, 0],
    ])
    .setPlotsIds('TRANSPARENT_AREA');

  // LABELS
  chart
    .addPlot('CHART_LABELS', 'text')
    .setOptionsPartially({ checkOverlap: false })
    .label.setOptions(true, BLACK, 'left', 0, [18, 'JetBrains Mono', '400'])
    .setCenterX(0)
    .setOffset(6, -18);

  const sevLabelsData = data.reduce<{ x: number; y: number; label: string }[]>(
    (pre, cur, ind, arr) => {
      const item = {
        x: 1,
        y: cur.severityValue.to,
        label: cur.severityLabel,
      };
      const curBot = cur.severityValue.from;
      const nextTop = ind < arr.length - 1 ? arr[ind + 1].severityValue.to : 0;
      if (curBot !== nextTop) {
        pre.push({
          x: 1,
          y: curBot,
          label: 'NOT COVERED',
        });
      }
      pre.push(item);
      return pre;
    },
    []
  );

  chart
    .addSeries(
      'SEVERITY_LABELS',
      [sevLabelsData.map(({ x }) => x), sevLabelsData.map(({ y }) => y)],
      sevLabelsData.map(({ label }) => label)
    )
    .setPlotsIds('CHART_LABELS');
  chart
    .addSeries(
      'NPV_LABELS',
      [data.map(() => 5), data.map(({ npvValue }) => npvValue.to * severityToNpvCoef)],
      data.map(({ npvLabel }) => npvLabel)
    )
    .setPlotsIds('CHART_LABELS');

  // SEVERITY TICKS
  chart.addPlot('CHART_TICKS_START', 'tick_y_start', 1, BLACK, 6);
  chart
    .addPlot('CHART_TICK_LABELS_START', 'text')
    .setOptionsPartially({ checkOverlap: false })
    .label.setOptions(true, BLACK_20, 'right', 0)
    .setFontOptions(15, 'JetBrains Mono', 'normal')
    .setCenterX(0)
    .setOffset(13, -1);
  chart
    .addSeries(
      'SEVERITY_TICKS',
      [data.map(() => 0), data.map(({ severityValue }) => severityValue.to)],
      data.map(({ severityValue }) => String(severityValue.to))
    )
    .setPlotsIds('CHART_TICKS_START', 'CHART_TICK_LABELS_START');

  // NPV TICKS
  chart.addPlot('CHART_TICKS_END', 'tick_y_end', 1, BLACK, -6);
  chart
    .addPlot('CHART_TICK_LABELS_END', 'text')
    .setOptionsPartially({ checkOverlap: false })
    .label.setOptions(true, BLACK_20, 'right', 0)
    .setFontOptions(15, 'JetBrains Mono', 'normal')
    .setCenterX(-1)
    .setOffset(-13, -1);
  chart
    .addSeries(
      'NPV_TICKS',
      [data.map(() => 9), data.map(({ npvValue }) => npvValue.to * severityToNpvCoef)],
      data.map(({ npvValue }) => npvValue.to.toFixed(2))
    )
    .setPlotsIds('CHART_TICKS_END', 'CHART_TICK_LABELS_END');

  // NPV AXIS
  chart.addPlot('AXIS_END', 'line_vertical', 1, BLACK_10);
  chart.addSeries('NPV_AXIS', [[9], [0]]).setPlotsIds('AXIS_END');

  // CHART INIT
  const yMinMax = chart.data.findExtremes('ind', chart.xAxis.min, chart.xAxis.max);
  chart.yAxis.setMinMaxStatic(yMinMax);
  chart.setCanvasPaddings(...canvasPaddings);
  chart.refresh();
  return { chart };
}
